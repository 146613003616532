<template>
  <svg
    fill="none"
    height="24"
    viewBox="0 0 26 24"
    width="26"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M6 6.22793C7.74894 4.24002 10.2241 3 12.9683 3C18.2679 3 22.5641 7.62462 22.5641 13.3294C22.5641 14.2529 22.4515 15.1481 22.2403 16M22.2403 16L25 13.3294M22.2403 16L19.8765 13.2424"
        stroke="red"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <path
        d="M20.1975 19.8231C18.3705 21.5857 15.8508 22.6229 13.1146 22.4994C7.83062 22.2608 3.74043 17.7839 3.97896 12.4999C4.0098 11.8166 4.11152 11.1533 4.27669 10.5167M4.27669 10.5167C4.01745 10.5812 2.36985 12.3018 1.39583 13.1541M4.27669 10.5167L6.50944 13.1541"
        stroke="red"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
    </g>
  </svg>
</template>
